.Product .mySwiper2 {
  width: calc(100% - 50px);
  height: 100vh;
}

.Product .productImg,
.Product .SproductImg {
  height: 100%;
  max-height: 400px;
  background-size: cover !important;
  background-position: center !important;
  width: calc(100% - 50px);
}

.Product .mySwiper {
  width: 50px;
  height: 80vh;
}

.Product .SproductImg {
  height: 65px;
  width: 100%;
}

.Product .details .price {
  color: var(--primary-color);
}

.Product .Sizes p {
  font-size: 1.05rem;
}

.Product .details .size {
  padding: 3px 12px;
  border: 1.99px solid var(--primary-color);
  cursor: pointer;
  font-size: 1.05rem;
  border-radius: 3px;
}

.Product .cartBtn {
  background: var(--primary-color);
  outline: none;
  border: none;
  padding: 10px 40px;
  color: white;
  margin-top: 10px;
  border-radius: 3px;
}

.Product .cartBtn:hover {
  background: var(--hover-color);
}

@media (max-width: 768px) {
  .Product .Slider,
  .Product .productImg {
    width: 100% !important;
  }
}


.brandfilter .active,
.brandfilter .badge{
  background: var(--primary-color);
}


.bg_primary{
  background: var(--primary-color) !important;
}