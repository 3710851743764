.banner {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 72vh;
}

.Hero .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.Hero .swiper-pagination-bullet-active {
  background: white !important;
}

/* Collection */
.Home .w-40 {
  width: 48%;
}

/* Newest Arrival */
.NewArrival .swiper-slide {
  display: flex;
  justify-content: center;
}

.NewArrival .swiper-button-next,
.NewArrival .swiper-button-prev {
  width: auto;
}

.NewArrival .swiper-button-next {
  right: 2px;
}

.NewArrival .swiper-button-prev {
  left: 2px;
}

.NewArrival .swiper-button-next:after,
.NewArrival .swiper-button-prev:after {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  color: black;
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  letter-spacing: 0;
}

@media (max-width: 550px) {
  .hero_card{
    justify-content: start !important;
    margin-top: 10px !important;
  }
  .Home .w-sm-100 {
    width: 100%;
  }
  .Home .section-head {
    width: 90% !important;
  }
  .banner {
    height: 40vh;
  }
  .NewArrival .swiper-button-next:after,
  .NewArrival .swiper-button-prev:after {
    font-size: 1.3rem;
    height: 40px;
    width: 40px;
  }
}
