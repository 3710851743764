.CartSection{
    background: gainsboro;
    height: 100vh;
}

.cartCard{
    width: 100%;
}
.cartCard img{
    height: 130px;
}

.products,
.payment{
    background: white;
    border-radius: 5px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    text-align: center;
  }
  
  .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  