.categoryImg{
  height: 20vh;
  object-fit: cover;
}

.Category .grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 20px;
}

@media (max-width: 610px) {
  .Category .grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  }
}

@media (max-width: 470px) {
  .Category .grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
    grid-gap: 15px;
  }
  .Card .title,
  .Card .price {
    font-size: 1.05rem !important;
  }
}
