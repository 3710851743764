@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --primary-color: #024e82;
  --hover-color: #045e9b;
  --gray-bg: #f0eeee;
}

/* Navbar */
.Navbar {
  background: white;
}

.Navbar .navbar-collapse {
  width: auto;
}

.Navbar .nav-link {
  transition: 0.2s all ease;
  font-size: 1.05rem;
  padding: 10px 20px !important;
  border-radius: 3px;
}

.Navbar .nav-link:hover {
  background: rgb(236, 236, 236);
}

.Navbar .searchBar {
  background: var(--gray-bg);
  border-radius: 20px;
  height: 40px;
  width: 260px;
}

.Navbar .searchBar input {
  outline: none;
  background: transparent;
  border: none;
  height: 100%;
  width: 100%;
  padding: 5px 0px 5px 20px;
}

.Navbar .searchBar button {
  background: black;
  padding: 0px 10px;
  border-radius: 20px;
  height: 100%;
}

.Navbar .button {
  background-color: var(--primary-color);
  outline: none;
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
}

.Navbar .button-outline {
  border: 1.99px solid var(--primary-color);
  outline: none;
  padding: 3.99px 15px;
  border-radius: 5px;
  background-color: transparent;
  color: black;
  text-decoration: none;
}

.Navbar .button-outline:hover {
  background-color: var(--primary-color);
  color: white;
}

.Navbar .cart-icon {
  position: relative;
}

.Navbar .items {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(0% - 10px);
  right: calc(0% - 10px);
  background: #0097ff;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  z-index: 20000;
  font-size: 0.8rem;
  font-weight: bold;
}

.canvas {
  width: 90%;
  margin: auto;
}

.button {
  background: black;
  color: white;
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.2s all ease;
}

.button:hover {
  opacity: 0.7;
}

.Card .title {
  text-decoration: none;
  color: black;
}

.Card .prod-img {
  width: 300px !important;
  height: 300px !important;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: contain;
}

.Card .price {
  color: black;
}

/* footer */
footer {
  background: rgb(245, 245, 245);
  padding: 10px 0px;
  margin-top: 100px;
}

footer .social_icon{
  background: var(--primary-color);
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

footer .footer-col {
  width: 220px;
  margin-top: 30px;
}

footer .footer-col:first-child {
  width: 300px;
  margin-top: 30px;
}

footer .footer-colsm {
  width: 150px;
  margin-top: 30px;
}


footer .newsletter input {
  outline: none;
  border: 1.99px solid gray;
  padding: 5px;
  height: 40px;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

footer .newsletter button {
  outline: none;
  border-top-right-radius: 5px;
  height: 40px;
  border: 1.99px solid black;
  padding: 10px;
  border-bottom-right-radius: 5px;
  background: black;
}

.highlight {
  color: #0492f0;
  font-weight: bold;
}

.highlightP {
  color: var(--primary-color);
}

.bg_prim {
  background: var(--primary-color) !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0% !important;
}

@media (max-width: 400px) {
  footer p {
    font-size: 0.95rem;
  }

  .canvas {
    width: 93%;
  }
}


.BrandSlider .swiper-wrapper {
  display: flex !important;
  align-items: center !important;
}